import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from './Image.js';

const propTypes = {
    src: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string
}

const defaultProps = {
    src: undefined,
    width: undefined,
    height: undefined,
    alt: undefined
}

const PackImage = ({
    className,
    src,
    width,
    height,
    alt,
    hoverImg,
    ...props
}) => {

    const [loaded, setLoaded] = useState(false);

    const image = useRef(null);

    useEffect(() => {
        handlePlaceholder(PackImage.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const placeholderSrc = (w, h) => {
        return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
    }

    const handlePlaceholder = (img) => {
        if (img === undefined) return;
        const placeholder = document.createElement('img');
        if (!loaded) {
            img.style.display = 'none';
            img.before(placeholder);
            placeholder.src = placeholderSrc(
                img.getAttribute('width') || 0,
                img.getAttribute('height') || 0
            );
            placeholder.width = img.getAttribute('width');
            placeholder.height = img.getAttribute('height');
            placeholder.style.opacity = '0';
            img.className && placeholder.classList.add(img.className);
            placeholder.remove();
            img.style.display = '';
        }
    }

    function onLoad() {
        setLoaded(true);
    }

    return (
        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
            <Image
                src={src}
                alt=""
                width={528}
                height={396} />
        </div>
    );
}


PackImage.propTypes = propTypes;
PackImage.defaultProps = defaultProps;

export default PackImage;