import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              {/* <span className="text-color-primary">Mixgrid</span> */}
              <Image src={require('../../assets/images/mixgrid_icon_with_shadow.png')} width={250} />
            </h1>
            <div className="small-spacer"></div>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Perfomance-built audio layering app designed with the user in foremind.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <div>
                    <a href='https://play.google.com/store/apps/details?id=app.mixgrid.mixgrid&utm_source=mixgrid_site&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                      <img style={{ height: 75 }} alt='Get it on Google Play' src={require('./../../assets/images/en_badge_web_generic-cut.png')} />
                    </a>
                  </div>
                  <div>
                    <a href="https://apps.apple.com/us/app/mixgrid-music-beat-maker/id6444157870?itsct=apps_box_badge&itscg=30200">
                      <img style={{ height: 75 }} alt='Get it on App Store' src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1635465600&h=8c67bcbdb68aadb98d67939c201e4566" />
                    </a>
                  </div>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://www.youtube.com/embed/eojVasQs3vw?controls=0&autoplay=1"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <div
                width={896}
                height={504} >
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/mixgrid-showcase-video-placeholder.jpg')}
                  alt="Hero" />
              </div>
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/eojVasQs3vw?controls=0&autoplay=1"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;