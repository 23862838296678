import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import HoverImage from '../elements/HoverImage';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  // const sectionHeader = {
  //   title: 'Simple and Effective.',
  //   paragraph: 'Use our simple timeline to drag and drop complex sounds designed by our signed professional artists to create your desired mix.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content reveal-from-right" /> */}
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Simple and Elegant
                </div>
                <h3 className="mt-0 mb-12">
                  Timeline-based interface
                </h3>
                <p className="m-0">
                  Our timeline-based interface is designed to be intuitive and easy to use, allowing you to create music tracks with a simple drag-and-drop approach.
                </p>

              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/mixgrid/feature_1.jpg')}
                  alt="Features split 01"
                  max-height={150}
                  width={528}
                  height={396}
                  style={{ overflow: "hidden", borderRadius: "20px" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Capture and Share your Creations
                </div>
                <h3 className="mt-0 mb-12">
                  Save, Load, and Export Projects
                </h3>
                <p className="m-0">
                  Our app provides a seamless experience for saving and loading your projects, allowing you to revisit and refine your compositions at any time. Additionally, you have the ability to export your projects as audio files.
                </p>

              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/mixgrid/feature_2.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  style={{ overflow: "hidden", borderRadius: "20px" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Sound effects? We have them
                </div>
                <h3 className="mt-0 mb-12">
                  High-performance Audio FX
                </h3>
                <p className="m-0">
                  Add color to your mixes by using our in-house built audio processing unit. Our audio processing unit offers a wide range of effects including echo, reverb, filter, trans, noise, flanger, phaser, bitcrusher, roll, tremolo, and vibrato.
                  <br></br>
                  Use dual FX pads to maximize the smoothness of your mix.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/mixgrid/feature_3.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  style={{ overflow: "hidden", borderRadius: "20px" }}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;