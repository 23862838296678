import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import PropTypes from 'prop-types';
import Input from '../elements/Input';
import Modal from '../elements/Modal';
import { useHistory } from 'react-router-dom';

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}


const ContactUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const [messageSent, setMessageSent] = useState(false);
    const [buttonEnabled, setButtonState] = useState(true);
    let history = useHistory();
    const [resultScreenActive, setResultScreenActive] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_w3m3hlk", "template_ipdwmpv", e.target, 'user_MTgEtDafpK4tKKgEkWM9l')
            .then((result) => {
                setMessageSent(true);
            }, (error) => {
                setMessageSent(false);
            }).then(() => {
                showResultScreen(e);
                setButtonState(true);
            });
    }
    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );
    const innerClasses = classNames(
        'contact-card section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        'cta-split'
    );

    const showResultScreen = () => {
        setResultScreenActive(true);
    }

    const closeResultScreen = () => {
        setResultScreenActive(false);
    }

    const submitButtonLogic = (e) => {
        setButtonState(false);
        sendEmail(e);
    }

    const returnButtonLogic = (e) => {
        e.preventDefault();
        if (messageSent) {
            history.push('/');
        }
        else {
            closeResultScreen();
        }
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            {/* <Modal
                id="video-modal"
                show={resultScreenActive}
                handleClose={closeResultScreen}
                video="https://player.vimeo.com/video/174002812"
                videoTag="iframe" /> */}
            {!resultScreenActive ? <div show={resultScreenActive} className="container" >
                <div
                    className={innerClasses}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <a href='/' style={{ color: '#e22249 !important', alignSelf: 'flex-start', paddingBottom: '1.2rem' }}>&lt; Return</a>
                    <form onSubmit={submitButtonLogic}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <label>Name</label>
                                <Input type="text" placeholder="Your name" className="contact-input" name="user_name" />
                            </div>
                            <div className="small-horizontal-spacer" />
                            <div>
                                <label>Email</label>
                                <Input type="email" placeholder="Your email" className="contact-input" name="user_email" />

                            </div>
                        </div>
                        <label>Message</label>
                        <Input type='textarea' placeholder="Your message" className="contact-form-message contact-input" name="message" />
                        <div className="small-spacer" />
                        <Input type="submit" style={{ backgroundColor: '#f1f1f1', cursor: 'pointer' }} value="Send" />
                    </form>
                </div>
            </div> : <div show={resultScreenActive} className="container" >
                <div
                    className={innerClasses}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    {messageSent ? 'Your message has been successfully sent.' : 'There was a problem sending your message.'}
                    <div className="small-spacer" />
                    <Input disabled={!buttonEnabled} type="button" onClick={returnButtonLogic} style={{ backgroundColor: '#f1f1f1', cursor: 'pointer' }} value={messageSent ? "Return" : "Try again"} />

                </div>
            </div>}
        </section>
    );

}
ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;
